import {
  AlgoliaQueryType,
  ProductData,
  Image as ImageProps,
  LinkProps,
} from '@interflora/ui-components/build/common/props'
import ProductListingUI from '@interflora/ui-components/build/components/ProductListing/ProductListing'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BasicDoc, connectStateResults, HitsProvided, StateResultsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import AnalyticsContext from '../../../context/AnalyticsContext'
import { useDefaultIndexName } from '../ProductListing'
import { SpotlightBannerBackgroundColor } from '@interflora/ui-components/build/styles/jss/utils'
type Hit = BasicDoc & { data: ProductData }

type Props = HitsProvided<Hit> & {
  componentId?: string
  componentType?: string
  sortConfig: {
    defaultRefinement: string
    items: {
      value: string
      label: string
    }[]
  }
  isInternational?: boolean
  queryType?: AlgoliaQueryType
  categoryName?: string
  spotlightBanner?: {
    body?: string
    backgroungImage?: ImageProps
    transparentOverlay?: number
    title?: string
    backgroundColor?: SpotlightBannerBackgroundColor
    bannerLink?: string
    buttonLink?: LinkProps
    desktopPosition?: number
    mobilePosition?: number
  }
  facetDetails?: (name: string, value: string) => void
  sendSpotlightBannerAnalytics?: (clickText: string) => void
}

type ListingAnalyticsProps = {
  componentId: string
  componentType: string
  setIndexName: React.Dispatch<React.SetStateAction<string>>
  queryType?: AlgoliaQueryType
} & StateResultsProvided<Hit>

/**
 * Component to call the analytics event once the results are ready
 */
const ListingAnalytics = connectStateResults<ListingAnalyticsProps>((props) => {
  const analytics = useContext(AnalyticsContext)
  const { componentId, componentType, searchResults, searchState, setIndexName, queryType } = props
  const index = searchResults?.index
  const searchResultsCopy = searchResults as any
  // Feedback index to parent component so it can embed it in the hit data
  // Not ideal but really difficult to expose the index in another way
  useEffect(() => {
    setIndexName(index)
  }, [setIndexName, index])

  const keysRef = useRef<string>()
  useEffect(() => {
    const keys = searchResults?.hits.map((hit) => hit.key).join(',')
    if (searchResults && keys !== keysRef.current) {
      keysRef.current = keys
      const products = searchResults?.hits.map((hit) => hit.data).filter(Boolean)
      analytics.viewProductList({ id: componentId, name: componentType, products })
    }
  }, [analytics, componentId, componentType, searchResults])

  const viewedFilterRef = useRef<string>()
  useEffect(() => {
    // See if category filter has changed and trigger view event
    const filters = searchState?.configure?.filters
    if (searchState && index && filters !== viewedFilterRef.current) {
      viewedFilterRef.current = filters
      filters &&
        analytics.algoliaViewedFilters(
          index,
          filters,
          queryType,
          `${searchResultsCopy?.abTestID}`,
          `${searchResultsCopy?.abTestVariantID}`
        )
    }
  }, [analytics, index, searchState, queryType])

  const clickedFiltersRef = useRef<string[]>()
  useEffect(() => {
    if (searchState && index) {
      // See if refinements or multi ranges have changed and trigger click event for any additions

      // convert refinement list from {"occasions":["I'm Sorry", "Birthday"]} to ["occasions:I'm Sorry","occasions:Birthday"]
      const refinementList = searchState?.refinementList || {}
      const refinementFilters = Object.keys(refinementList).reduce(
        (filters: string[], key) =>
          refinementList[key] ? [...filters, ...refinementList[key].map((value) => `${key}:${value}`)] : filters,
        []
      )

      const filters = refinementFilters // [...refinementFilters, ...rangeFilters]
      // Only send the added filters
      const addedFilters = filters.filter((value) => !clickedFiltersRef.current?.some((previous) => value === previous))
      !!addedFilters.length &&
        analytics.algoliaClickedFilters(
          index,
          addedFilters.join(','),
          queryType,
          `${searchResultsCopy?.abTestID}`,
          `${searchResultsCopy?.abTestVariantID}`
        )

      // update the filters so we dont send events for any of them again
      clickedFiltersRef.current = filters
    }
  }, [analytics, index, searchState, queryType])

  return null
})

const ListingWrapper = (props: Props) => {
  const {
    hits,
    componentId,
    componentType,
    sortConfig,
    isInternational,
    queryType,
    categoryName,
    spotlightBanner,
    facetDetails,
    sendSpotlightBannerAnalytics,
  } = props
  const keysRef = useRef<string>('')
  const [products, setProducts] = useState<ProductData[]>([])
  const defaultIndexName = useDefaultIndexName()
  const [indexName, setIndexName] = useState(defaultIndexName)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const keys = hits.map((hit) => hit.key).join(',')
    if (keys !== keysRef.current) {
      keysRef.current = keys

      setProducts(
        hits
          .map(
            (hit): ProductData => ({
              ...hit.data,
              objectId: hit.objectID,
              position: hit.__position,
              queryId: hit.__queryID,
              queryType,
              indexName,
            })
          )
          .filter(Boolean)
      )
      setIsLoading(false)
    }
  }, [hits, indexName, queryType])
  return (
    <>
      <ListingAnalytics
        componentId={componentId}
        componentType={componentType}
        setIndexName={setIndexName}
        queryType={queryType}
      />
      <ProductListingUI
        products={products}
        sortConfig={sortConfig}
        isInternational={isInternational}
        categoryName={categoryName}
        data-hj-ignore-attributes
        spotlightBanner={spotlightBanner}
        facetDetails={facetDetails}
        sendSpotlightBannerAnalytics={sendSpotlightBannerAnalytics}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default connectHits<Props, Hit>(ListingWrapper)
