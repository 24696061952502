import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Configure } from 'react-instantsearch-dom'
import AlgoliaWrapper from '../AlgoliaWrapper/AlgoliaWrapper'
import ProductListingWrapper from './ProductListingWrapper/ProductListingWrapper'
import {
  AlgoliaQueryType,
  LinkProps,
  MobileImage,
  Image as ImageProps,
} from '@interflora/ui-components/build/common/props'
import { removeSessionItems, SiteContext } from '@interflora/ui-components/build/utils/common'
import { SpotlightBannerBackgroundColor } from '@interflora/ui-components/build/styles/jss/utils'
import AnalyticsContext from 'context/AnalyticsContext'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from '@material-ui/core'
import { useFeatureFlagExperiment } from '@interflora/ui-components/build/utils/useExperiment'
import { flag_category_page_initial_results_count } from '@interflora/ui-components/build/utils/featureFlags'
interface Props {
  category?: {
    id: string
    key: string
    name: string
    isInternational: boolean
  }
  uiType?: string
  meta?: {
    deliveryId: string
  }
  spotlightBanner?: {
    body?: string
    backgroungImage?: ImageProps
    mobileBackgroundImage?: MobileImage
    transparentOverlay?: number
    title?: string
    backgroundColor?: SpotlightBannerBackgroundColor
    bannerLink?: string
    buttonLink?: LinkProps
    desktopPosition?: number
    mobilePosition?: number
  }
  facetDetails?: (name: string, value: string) => void
  sendSpotlightBannerAnalytics?: (click_text: string) => void
}

type FilterState = {
  filters: string
  queryType?: AlgoliaQueryType
}

const emptyFilterState = {
  filters: '',
  query: '',
}

/**
 * Create the default index name for product searches, with optional international and ranking parts
 */
export const useDefaultIndexName = (isInternational?: boolean) => {
  const { shorthand } = useContext(SiteContext)
  const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
  const indexSuffix = isInternational ? 'intl_' : ''
  return `${envPrefix}_product_${indexSuffix}${shorthand}`
}

/**
 * Product Listing Component for Category/PLP
 *
 * Doesn't render until the filter state has been set for the first time as its driven from changes in the router which is not always ready on first render
 * Uses a key of the filter state so the Algolia search is recreated each time it changes, clearing down the facets, as clearing them manually can cause two queries to happen
 * flashing the wrong results on the screen and triggering two analytic events
 */

const ProductListing = ({ category, meta, uiType, spotlightBanner }: Props) => {
  const resultsCountVariant = useFeatureFlagExperiment(
    flag_category_page_initial_results_count.key,
    typeof window !== 'undefined' && window.location.pathname.startsWith('/category/')
  )
  const [productPageLimit, setProductPageLimit] = useState(12)
  const router = useRouter()
  const theme = useTheme()
  const [isMobile, setIsMobile] = useState(false)
  const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` })
  const previousCategoryKey = useRef('')
  const { shorthand } = useContext(SiteContext)
  const analytics = useContext(AnalyticsContext)
  const [filters, setFilters] = useState<FilterState>(null)
  const defaultIndex = useDefaultIndexName(category?.isInternational)
  const [defaultIndexname, setDefaultIndexName] = useState(`${defaultIndex}`)

  const sortConfig = {
    defaultRefinement: defaultIndexname,
    items: [
      { value: `${defaultIndex}`, label: 'Recommended' },
      { value: `${defaultIndex}_price_asc`, label: 'Price low - high' },
      { value: `${defaultIndex}_price_desc`, label: 'Price high - low' },
    ],
  }
  const [sortConfigState, setConfigState] = useState(sortConfig)
  const facetDetails = (name: string, value: string) => {
    if (name == 'sortBy') {
      analytics.facetSortBy(value)
    } else {
      analytics.facetFilters(name, value)
    }
  }

  useEffect(() => {
    // Dont do anything till router is ready
    if (router.isReady && category?.key && previousCategoryKey?.current !== category?.key) {
      previousCategoryKey.current = category?.key
      setFilters({ ...emptyFilterState, queryType: 'PLP', filters: `allCategories:${category.key}` })
    }
  }, [category?.key, router.isReady, router.query])

  useEffect(() => {
    if (category?.isInternational) {
      const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
      const indexName = `${envPrefix}_product_intl_${shorthand}`
      setConfigState({ ...sortConfig, defaultRefinement: `${indexName}` })
    } else {
      setConfigState({ ...sortConfig, defaultRefinement: `${defaultIndex}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.isInternational])

  const updateDefaultIndex = useCallback(() => {
    const sessionFiltersList = sessionStorage.getItem('sessionFilters')
    const parseFilters = sessionFiltersList ? JSON.parse(sessionFiltersList) : undefined
    let filter
    if (!sessionStorage.getItem('loadAll'))
      setProductPageLimit(
        resultsCountVariant === flag_category_page_initial_results_count.explicit_count ? (!isMobile ? 40 : 20) : 12
      )
    if (!!parseFilters && !!parseFilters['sortBy']) {
      filter = `${parseFilters['sortBy']}`
      filter && setDefaultIndexName(filter)
    } else {
      setDefaultIndexName(`${defaultIndex}`)
      setConfigState({ ...sortConfig, defaultRefinement: `${defaultIndex}` })
    }
  }, [defaultIndex])

  useEffect(() => {
    window?.addEventListener('storage', updateDefaultIndex)
    return () => {
      window?.removeEventListener('storage', updateDefaultIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDefaultIndex])

  useEffect(() => {
    const removeSessionList = ['isFromPDP', 'productScrollIntoView', 'loadAll', 'sessionFilters', 'categoryName']
    const sessionCategoryName = sessionStorage?.getItem('categoryName')
    if (!!sessionCategoryName && sessionCategoryName !== category?.name) removeSessionItems(removeSessionList)
  }, [category?.name])

  useEffect(() => {
    setIsMobile(isMobileDevice)
  }, [isMobileDevice])

  useEffect(() => {
    if (resultsCountVariant === flag_category_page_initial_results_count.explicit_count) {
      setProductPageLimit(!isMobile ? 40 : 20)
    }
  }, [isMobile, resultsCountVariant])

  /** saving category spotlight banner analytics */
  const sendSpotlightBannerAnalytics = (clickText: string) => {
    analytics?.categorySpotLightBanner(clickText, category?.name)
  }

  const fetchPageNo = () => {
    const pageNo = router?.query?.page ? parseInt(router?.query?.page?.toString()) : 0

    if (pageNo <= 0) return 0
    else return pageNo - 1
  }

  const pageNumber = fetchPageNo()
  return (
    filters &&
    resultsCountVariant && (
      <AlgoliaWrapper indexName={defaultIndexname} key={JSON.stringify(filters)}>
        <Configure
          clickAnalytics
          hitsPerPage={productPageLimit}
          distinct={true}
          filters={filters.filters || ''}
          page={pageNumber}
        />
        <ProductListingWrapper
          componentId={meta.deliveryId}
          componentType={uiType}
          isInternational={!!category?.isInternational}
          sortConfig={sortConfigState}
          queryType={filters.queryType}
          categoryName={category?.name}
          spotlightBanner={spotlightBanner}
          facetDetails={facetDetails}
          sendSpotlightBannerAnalytics={sendSpotlightBannerAnalytics}
        />
      </AlgoliaWrapper>
    )
  )
}

export default ProductListing
