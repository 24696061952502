import {
  AlgoliaQueryType,
  BaseOrder,
  Cart,
  DetailProduct,
  LineItem,
  Order,
  ProductData,
  ProductOrderFrom,
} from '@interflora/ui-components/build/common/props'
import { convertCategoryNames, getAttribute } from '@interflora/ui-components/build/utils/common'

export interface AnalyticsOptions {
  gtmId: string
}

/**
 * Controls the population of Google Analytics' `dataLayer` object.
 */
export class Analytics {
  public currency = ''
  private readonly gtmId: string
  public subject: any = null
  private isBuffering = false
  private dataLayerBuffer = []
  private user_id: string = null
  private email: string = null

  /**
   * Construct the Analytics class.
   */
  constructor(options: AnalyticsOptions) {
    this.gtmId = options.gtmId
    if (!this.gtmId) {
      throw new Error('Analytics - No GTM ID provided to constructor')
    }
  }

  /**
   * Turn on buffering. This is generally only needed for when we transition
   * between pages. This is because the `page_view` event needs to be fired
   * before any other events when a new page is rendered.
   */
  bufferEvents() {
    console.debug('Analytics - turning on buffering')
    this.isBuffering = true
  }

  /**
   * Flush any buffered events to the `dataLayer` array.
   */
  flushEvents() {
    console.debug('Analytics - flushing buffered data layer')
    this.dataLayerBuffer.forEach((eventObject) => {
      console.debug('Analytics - adding to data layer from buffer', eventObject)
      window.dataLayer.push(eventObject)
    })
    this.dataLayerBuffer = []
    this.isBuffering = false
    console.debug('Analytics - buffering turned off')
  }

  /**
   * Triggers a 'view_item` event on the dataLayer.
   */
  viewProduct(product: DetailProduct, indexName?: string, categoryNames?: string[]) {
    this.event('view_item', {
      items: [this.toEventProduct(product, undefined, categoryNames)],
      _clear: true,
      algoliaObjectIDs: product.id,
      algoliaIndexName: indexName,
    })
  }

  /**
   * Triggers a 'view_item_list` event on the dataLayer.
   */
  viewProductList(data: { id: string; name: string; products: ProductData[]; categoryNames?: string[] }) {
    this.event('view_item_list', {
      items: data.products.map((product, index) => this.toEventProduct(product, index, data.categoryNames)),
      _clear: true,
      item_list_name: data.name,
      item_list_id: data.id,
    })
  }

  /**
   * Triggers a 'algoliaViewedFilters` event on the dataLayer.
   */
  algoliaViewedFilters(
    indexName: string,
    filters?: string,
    queryType?: AlgoliaQueryType,
    abTestID?: string,
    abTestVariantID?: string
  ) {
    this.event('algoliaViewedFilters', {
      algoliaIndexName: indexName,
      algoliaFilters: filters,
      algoliaEventNamePrefix: queryType ? `${queryType};` : null,
      algoliaAbTestID: abTestID,
      algoliaAbTestVariantID: abTestVariantID,
    })
  }

  /**
   * Triggers a 'algoliaClickedFilters` event on the dataLayer.
   */
  algoliaClickedFilters(
    indexName?: string,
    filters?: string,
    queryType?: AlgoliaQueryType,
    abTestID?: string,
    abTestVariantID?: string
  ) {
    this.event('algoliaClickedFilters', {
      algoliaIndexName: indexName,
      algoliaFilters: filters,
      algoliaEventNamePrefix: queryType ? `${queryType};` : null,
      algoliaAbTestID: abTestID,
      algoliaAbTestVariantID: abTestVariantID,
    })
  }

  /**
   * Triggers a ' SortBy facet filter buttons on our category pages' event on the dataLayer.
   */
  facetSortBy(value: string) {
    try {
      this.event(
        'sort_by',
        {
          value_selected: value,
        },
        true
      )
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Triggers a 'filter buttons on our category pages' event on the dataLayer.
   */
  facetFilters(attribute: string, value: string) {
    try {
      this.event(
        'filters',
        {
          facet_name: attribute,
          facet_value: value,
        },
        true
      )
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'view_cart' event on the dataLayer.
   */
  viewCart(cart?: Cart) {
    const items = this.convertBaseOrderItems(cart)
    try {
      this.event(
        'view_cart',
        {
          cart_id: cart?.id,
          items: items?.length ? items : null,
          _clear: true,
          value: cart?.totalPrice?.formatted?.slice(1) || 0,
          currency: this.currency,
        },
        true
      )
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'checkout_step_progress' event on the dataLayer.
   */
  viewCheckout(cart?: Cart) {
    const items = this.convertBaseOrderItems(cart)
    const shipping_tier =
      cart?.consignments?.find((consignment) => consignment?.delivery?.serviceCode)?.delivery?.serviceCode || null

    try {
      this.event(
        'view_checkout',
        cart
          ? {
              items: items?.length ? items : null,
              _clear: true,
              value: cart.totalPrice?.formatted?.slice(1) || 0,
              currency: this.currency,
              shipping_tier,
              coupon: cart?.couponCode || null,
            }
          : {}
      )
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'checkout_step_progress' event on the dataLayer.
   */
  purchaseOrder(order: Order) {
    const items = this.convertBaseOrderItems(order)
    try {
      this.event(
        'purchase',
        {
          items: items?.length ? items : null,
          _clear: true,
          value: order.totalPrice?.formatted?.slice(1) || 0,
          currency: this.currency,
          transaction_id: order?.orderNumber,
          shipping: order.deliveryCost?.formatted?.slice(1) || 0,
          coupon: order.couponCode || null,
        },
        true
      )
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'view_page` event on the dataLayer. When a new page
   * is loaded, this should be fired before any other events in order
   * for those other events to be associated with the correct page. This
   * is the purpose of the buffering functionality in this class.
   */
  viewPage(data: any) {
    console.log('view page tag firing from code', data)
    this.event('page_view', data, true)
  }

  /**
   * Triggers an 'add_to_order' event on the dataLayer.
   */
  addToOrder(detail: any) {
    try {
      this.event('add_to_order', detail)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers an 'add_to_cart' event on the dataLayer. Can override the quantity on the item if specified.
   */
  addToCart(cart_id: string, item: LineItem, quantity?: number, queryId?: string, indexName?: string) {
    const items = [this.toEventLineItem(item, quantity)]
    try {
      this.event('add_to_cart', {
        cart_id,
        items,
        _clear: true,
        algoliaObjectIDs: item.productId,
        algoliaQueryID: queryId,
        algoliaIndexName: indexName,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'remove_to_cart' event on the dataLayer. Can override the quantity on the item if specified.
   */
  removeFromCart(cart_id: string, item: LineItem, quantity?: number) {
    const items = [this.toEventLineItem(item, quantity)]
    try {
      this.event('remove_from_cart', { cart_id, items, _clear: true })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'login' event on the dataLayer.
   */
  signIn(user_id: string, email: string) {
    try {
      this.event('login', { method: 'commercetools' })
      this.setUserDetails(user_id, email)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'logout' event on the dataLayer.
   */
  signOut() {
    try {
      this.event('logout', { method: 'commercetools' })
      this.setUserDetails(null, null)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a 'register' event on the dataLayer.
   */
  register() {
    try {
      this.event('register', { method: 'commercetools' })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Sets the user id on the data layer
   */
  setUserDetails(user_id: string, email: string) {
    if (this.user_id !== user_id || this.email !== email) {
      try {
        this.event('user_updated', { user_id, email })
        this.user_id = user_id
        this.email = email
      } catch (err) {
        console.error(err)
      }
    }
  }

  /**
   *   Triggers a 'pdpPath' event on the dataLayer.
   */
  pdpPath(delivery_date: string, postcode: string, journey_type: string) {
    try {
      this.event('pdp_path', {
        delivery_date: delivery_date,
        postcode: postcode,
        journey_type: journey_type,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   *   Triggers a 'Old Calendar Pdp Path' event on the dataLayer.
   */
  oldCalendarJourney(delivery_date: string, postcode: string, journey_type: string) {
    try {
      this.event('old_calendar_journey', {
        delivery_date: delivery_date,
        postcode: postcode,
        journey_type: journey_type,
      })
    } catch (err) {
      console.error(err)
    }
  }

  pdp_delivery_date_completed(delivery_date: string) {
    try {
      this.event('pdp_delivery_date_completed', {
        delivery_date: delivery_date,
      })
    } catch (err) {
      console.error(err)
    }
  }

  productVariantChange(sku) {
    try {
      this.event('product_variant_change', {
        productVariantId: sku,
      })
    } catch (err) {
      console.error(err)
    }
  }

  addressErrorEvent(data: any) {
    try {
      this.event('address_error_event', {
        first_name: data?.firstName,
        last_name: data?.lastName,
        phone_number: data?.phone,
        recipient_address: data?.recipientAddress,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers an event when user enter price lessthan minimum open price
   */
  minOpenPriceErrorEvent(variant: string, data: any) {
    try {
      this.event('min_open_price_error', {
        variant,
        min_open_price_value: data,
      })
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Triggers an event when user enter price morethan 499
   */
  maxOpenPriceErrorEvent(variant: string, data: any) {
    try {
      this.event('max_open_price_error', {
        variant,
        max_open_price_value: data,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers an event when user doesn't enter any value
   */
  openPriceErrorEvent(variant: string, data: any) {
    try {
      this.event('open_price_error', {
        variant,
        open_price_error: data,
      })
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Triggers an event when user enter valid openPrice
   */
  openPriceEvent(variant: string, data: any) {
    try {
      this.event('open_price_event', {
        variant,
        open_price_value: data,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   *   Triggers a 'selected product from' event on the dataLayer.
   */
  productOrderedFrom(item: ProductOrderFrom) {
    try {
      this.event('product_ordered_from', {
        product_name: item.productName,
        product_ordered: item.productOrdered,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   *   Triggers an event when user provides a personal message during order flow
   */
  personalMessageLocation(location: string) {
    try {
      this.event('personal_message_box_location', {
        personal_message_location: location,
      })
    } catch (err) {
      console.error(err)
    }
  }

  pdpThumbnailClick(position: number) {
    try {
      this.event(
        'pdp_thumbnail_click',
        {
          pdp_thumbnail_position: position,
        },
        true
      )
    } catch (err) {
      console.error(err)
    }
  }
  /**
   *   Triggers a 'selected product from' event on the dataLayer.
   */
  addThisGiftForAnotherPersonBasket(add_this_gift_for_another_person_basket: string) {
    try {
      this.event('add_this_gift_for_another_person_basket', {
        add_this_gift_for_another_person_basket,
      })
    } catch (err) {
      console.error(err)
    }
  }

  alternateProductCalendarJourney(date: string) {
    try {
      this.event('alternate_product_calendar_journey', {
        delivery_date: date,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Triggers a IDP event journey click
   */
  idpJourney(journey_path: string, idp_product_type: string) {
    try {
      this.event('idp_journey', {
        idp_journey_path: journey_path,
        idp_product_type: idp_product_type,
      })
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Occasion_Reminder  event
   *
   */

  occasions(occasion: string[], occasionPage: string) {
    try {
      this.event('occasion_reminder', {
        occasion_set: occasion,
        occasion_reminder_creation_page: occasionPage,
        occasion_reminder_count: occasion.length,
      })
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Triggers a Search Redirect event on a data layer.
   */
  searchRedirect(url: string) {
    try {
      this.event('search_redirect', {
        redirect_url: url,
      })
    } catch (err) {
      console.error(err)
    }
  }

  searchTerm(value: string) {
    try {
      this.event('search_term', {
        search_term_value: value,
      })
    } catch (err) {
      console.error(err)
    }
  }

  searchOverlayNoResults(value: string) {
    try {
      this.event('search_overlay_no_results', {
        message: value,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Responsible for actually appending/pre-pending an event on to
   * the `dataLayer` object.
   */
  event(name: string, data: any, prependIfBuffering = false) {
    if (!window?.dataLayer) {
      console.warn(`'No 'window.dataLayer' to add event '${name}' to with data: `, data)
      return
    }
    const eventObject = {
      ...data,
      event: name || undefined,
    }
    if (!this.isBuffering) {
      console.debug('Analytics - adding to data layer', eventObject)
      window.dataLayer.push(eventObject)
    } else {
      console.debug(`Analytics - buffering event${prependIfBuffering ? ' (prepended)' : ''}`, eventObject)
      if (prependIfBuffering) {
        this.dataLayerBuffer.unshift(eventObject)
      } else {
        this.dataLayerBuffer.push(eventObject)
      }
    }
  }

  /**
   * Ensure that line items are converted in to a consistent and expected format.
   */
  private toEventLineItem(lineItem: LineItem, quantity?: number) {
    const price = lineItem.price?.formatted.slice(1)
    const color = getAttribute('variantColor', lineItem.attributes)
    const size = getAttribute('variantSize', lineItem.attributes)
    const item_variant = [color, size].filter(Boolean).join(', ') || null
    const categoryNames = lineItem.category
      ? [
          lineItem.category.name,
          ...(lineItem.category.ancestors
            ?.slice()
            .reverse()
            .map((category) => category.name) || []),
        ]
      : null

    return {
      currency: this.currency,
      item_id: lineItem.product?.key || '',
      item_name: lineItem.product?.name || lineItem.name,
      item_variant,
      price,
      quantity: typeof quantity === 'number' ? quantity : lineItem.quantity || 1,
      c_item_variant_name: lineItem.name || null,
      c_item_variant_id: lineItem.sku || null,
      ...convertCategoryNames(categoryNames || lineItem?.product?.categoryNames),
      // affiliation: '<Affiliation Reference>', // not in Beta
    }
  }

  /**
   * Ensure that products are converted in to a consistent and expected format.
   */
  private toEventProduct(product: ProductData | DetailProduct, index: number = undefined, categoryNames?: string[]) {
    const masterVariant = (product as DetailProduct).variants?.find((v) => v.isMaster)
    const price = product.price.formatted.slice(1)
    const color = getAttribute('variantColor', masterVariant?.attributes)
    const size = getAttribute('variantSize', masterVariant?.attributes)
    const item_variant = [color, size].filter(Boolean).join(', ') || null
    return {
      currency: this.currency,
      item_id: product.key,
      item_name: product.name,
      item_variant,
      price,
      quantity: 1,
      index,
      c_item_variant_name: masterVariant?.name || null,
      c_item_variant_id: masterVariant?.sku || null,
      ...convertCategoryNames(categoryNames || (product as DetailProduct)?.categoryNames),
      // affiliation: '<Affiliation Reference>', // not in Beta
    }
  }

  applyCodeUsage(applyCodeCount: number, status: string, promoCode: string) {
    try {
      this.event('apply_code_usage', {
        discount_code_count: applyCodeCount,
        discount_code_status: status,
        discount_code_used: promoCode,
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Convert base order consignment line items and finishing touches into event items
   */
  convertBaseOrderItems = (order?: BaseOrder) =>
    order?.consignments?.reduce(
      (acc, consignment) => [
        ...acc,
        ...consignment.items.reduce(
          (acc, itemGroup) => [
            ...acc,
            this.toEventLineItem(itemGroup.lineItem),
            ...itemGroup.finishingTouchLineItems.map((ft) => this.toEventLineItem(ft)),
          ],
          []
        ),
      ],
      []
    )

  /** save category spotlight banner */
  categorySpotLightBanner(click_text: string, category: string) {
    try {
      this.event('category_spotlight', {
        click_text,
        category,
      })
    } catch (err) {
      console.error(err)
    }
  }
  menuDrillDownClick(drilldown_text: string) {
    try {
      this.event('menu_drilldown_click', {
        drilldown_text,
      })
    } catch (err) {
      console.error(err)
    }
  }
  menulinkClick(menu_link_text: string) {
    try {
      this.event('menu_link_clicked', {
        menu_link_text,
      })
    } catch (err) {
      console.log(err)
    }
  }
  postCodeCheckerAddressSuccess() {
    try {
      this.event('pcc_address_success', {
        pcc_success_address: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  postCodeCheckerAddressManual() {
    try {
      this.event('pcc_address_manual', {
        pcc_manual_address: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  postCodeCheckerOccasionSelected(occasion: string) {
    try {
      this.event('pcc_occasion_selected', {
        pcc_selected_occasion: occasion,
      })
    } catch (err) {
      console.error(err)
    }
  }

  postCodeCheckerComplete() {
    try {
      this.event('pcc_complete', {
        pcc_submit: true,
      })
    } catch (err) {
      console.error(err)
    }
  }
  postCodeCheckerOpen() {
    try {
      this.event('pcc_open', {
        pcc_click: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  postCodeCheckerDate() {
    try {
      this.event('pcc_date_selected', {
        pcc_selected_date: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  postCodeCheckerAddressManualClick() {
    try {
      this.event('pcc_address_manual_click', {
        pcc_manual_address_click: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  signInJourney(isSignInSuccess: boolean, sign_in_location: string) {
    try {
      this.event('sign_in', {
        sign_in_successful: isSignInSuccess,
        sign_in_unsuccessful: !isSignInSuccess,
        sign_in_location,
      })
    } catch (error) {
      console.error(error)
    }
  }

  continueAsGuest() {
    try {
      this.event('continue_as_guest', {
        continue_as_guest: true,
      })
    } catch (error) {
      console.error(error)
    }
  }

  createAnAccount(isCreateAccountSuccess: boolean) {
    try {
      this.event('create_an_account', {
        create_an_account_successful: isCreateAccountSuccess,
        create_an_account_unsuccessful: !isCreateAccountSuccess,
      })
    } catch (error) {
      console.error(error)
    }
  }
  paymentSelection(payment_selection_type: string, isPaymentSuccess: boolean) {
    try {
      this.event('payment_selection', {
        payment_selection_type,
        payment_success: isPaymentSuccess,
        payment_fail: !isPaymentSuccess,
      })
    } catch (err) {
      console.error(err)
    }
  }
  klarnaLearnMoreClick() {
    try {
      this.event('klarna_learn_more', {
        klarna_info_click: true,
      })
    } catch (error) {
      console.error(error)
    }
  }
}
