import { ApolloError, useMutation, useQuery } from '@apollo/client'
import {
  Address,
  Cart,
  Category,
  CountriesListProps,
  DeliveryOptionsOrAlternateProducts,
  DetailProduct,
  HandleSubmit,
  ProductData,
  SignInValues,
  StyleVariantInfoProps,
  TimedContentProps,
} from '@interflora/ui-components/build/common/props'
import { Props as FuneralUspGalleryProps } from '@interflora/ui-components/build/components/FuneralUspGallery/FuneralUspGallery'
import ProductDetailUI from '@interflora/ui-components/build/components/ProductDetail/ProductDetail'
import {
  getAttribute,
  PRODUCTS_ORDERED_FROM_STORAGE_KEY,
  removeSessionItems,
  SiteContext,
  updateDeliveryInstructionNote,
} from '@interflora/ui-components/build/utils/common'
import { useDefaultIndexName } from 'components/ProductListing/ProductListing'
import AnalyticsContext from 'context/AnalyticsContext'
import addMonths from 'date-fns/addMonths'
import { default as format, default as formatDate } from 'date-fns/format'
import { useSendPersonalMessageLocationToAnalytics } from '../../utils/saveAnalyticsForPersonalMessageLocation'

import {
  ADD_DELIVERY_PASS_TO_CART,
  ADD_NEW_ADDRESS,
  ADD_TO_CART,
  EDIT_CONTACT_ADDRESS,
  FORMATTED_ADDRESS_AND_PRODUCT_AVAILABILITY,
  GET_ALL_COUNTRIES_LIST,
  GET_CART,
  GET_DELIVERY_OPTIONS,
  GET_DELIVERY_OPTIONS_OR_ALATERNATE_PRODUCTS,
  GET_DIGITAL_PRODUCTS,
  IS_PRODUCT_FULFILLABLE,
  PRODUCT_AVAILABILITY,
  REMOVE_FROM_CART,
} from 'graphql/queries'
import { AddAddressInput } from 'graphql/types'
import { default as _get } from 'lodash/get'
import { useRouter } from 'next/dist/client/router'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { signIn, useImperativeQuery } from '../../lib/apolloClient'
import { populateTemplateValues } from '../../utils/components'
import { useSaveAuthAnalytics } from 'utils/saveAnalyticsForAuthEvents'
import { useLocalStorage } from '@interflora/ui-components/build/utils/utilityCustomHooks'

type AddToCartResult = {
  addToCart: {
    addedLineItemId: string
    consignmentLimitReached: boolean
    hasFinishingTouches: boolean
    cart: Cart
  }
}

type AddToCart = {
  sku: string
  occasion: string
  cardMessage?: string
  deliveryAddress: AddToCartAddress
  deliveryDate: string
  specialInstructions?: string
  openPrice?: string
}

type AddToCartAddress = {
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  postalCode: string
  city?: string
  county?: string
  country?: string
  firstName?: string
  lastName?: string
  phone?: string
  id?: string
  isFuneralDirector?: boolean
}

const AddToCartTemplate = {
  sku: '',
  occasion: '',
  cardMessage: '',
  deliveryDate: '',
  deliveryAddress: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    county: '',
    country: '',
    postalCode: '',
    phone: '',
    id: '',
    isFuneralDirector: false,
  },
  deliveryStandardRuleId: '',
  deliveryOptionalRuleId: '',
  deliveryInstructionOption: '',
  deliveryInstructionNote: '',
  deliveryPassSku: '',
  isInternational: false,
  isOverseas: false,
  isCourierUpgrade: false,
  categoryKey: '',
  specialInstructions: '',
  isDropship: false,
  openPrice: '',
}

const FuneralAddToCartTemplate = {
  ...AddToCartTemplate,
  deceasedName: '',
  funeralServiceDate: '',
  funeralServiceTime: '',
}

// Converts the form data into the graphQL values for mutation retrieving the product sku based on the variant
const convertFormValues = (values: any, product: DetailProduct): AddToCart => {
  const {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    county,
    postalCode,
    phone,
    country,
    isFuneralDirector,
    deliveryDate,
    variantColor,
    variantSize,
    variantStyle,
    funeralServiceDate,
    funeralServiceTime,
    isInternational,
    isOverseas,
    isCourierUpgrade,
    specialInstructions,
    isDropship,
    addressId,
    deliveryInstructionOption,
    openPrice,
    ...others
  } = values

  const sku = product.variants.find((variant) => {
    // either no variantColor or has same attribute
    const colorCheck = !variantColor || getAttribute('variantColor', variant.attributes) === variantColor
    // either no variantSize or has same attribute
    const sizeCheck = !variantSize || getAttribute('variantSize', variant.attributes) === variantSize
    //either no variantStyle or has same attribute
    const styleCheck = !variantStyle || getAttribute('variantStyle', variant.attributes) === variantStyle
    // has one of the variants or is the master
    const hasRequiredAttribute = variantColor || variantSize || variantStyle || variant.isMaster
    return colorCheck && sizeCheck && styleCheck && hasRequiredAttribute
  })?.sku

  if (!sku) {
    console.log('Could not retrieve variant sku for product', { variantColor, variantSize, variantStyle, product })
  }

  const deliveryInstructionOptionVal = deliveryInstructionOption === 'Press to select' ? '' : deliveryInstructionOption

  return {
    ...others,
    sku,
    deliveryAddress: {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      addressLine3,
      county,
      city,
      postalCode,
      country,
      phone,
      id: addressId,
      isFuneralDirector,
    },
    deliveryDate,
    funeralServiceDate: funeralServiceDate && format(funeralServiceDate, 'yyyy-MM-dd'),
    funeralServiceTime: funeralServiceTime?.replace(':', ''),
    isInternational,
    isOverseas,
    isCourierUpgrade,
    specialInstructions,
    isDropship,
    deliveryInstructionOption: deliveryInstructionOptionVal,
    openPrice,
  }
}

type Props = {
  product: DetailProduct
  funeralUspGallery: FuneralUspGalleryProps
  deliveryOptionsInformation?: { title: string; content: string }
  productVariantInformation?: { modalTitle: string; items: StyleVariantInfoProps[] }
  timedContentInformation?: TimedContentProps[]
}

const AddNewAddressTemplate: AddAddressInput = {
  firstName: '',
  lastName: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  county: '',
  postalCode: '',
  country: '',
  deliveryInstructionNote: '',
  deliveryInstructionOption: '',
}
const ProductDetail = (props: Props) => {
  const router = useRouter()
  const queryByCategory = router.query?.category
  const queryByIndexName = router?.query?.indexName
  const queryByQueryId = router.query?.queryId
  const analytics = useContext(AnalyticsContext)
  const { countryCode, getCustomer, isFlyingFlowers, subject, getConsignmentCount } = useContext(SiteContext)
  const getFormattedAddressAndAvailability = useImperativeQuery(FORMATTED_ADDRESS_AND_PRODUCT_AVAILABILITY)
  const getDeliveryOptions = useImperativeQuery(GET_DELIVERY_OPTIONS)
  const isProductFulfillable = useImperativeQuery(IS_PRODUCT_FULFILLABLE)
  const getProductAvailability = useImperativeQuery(PRODUCT_AVAILABILITY)
  const getDeliveryOptionsOrLaternateProductsMutations = useImperativeQuery(GET_DELIVERY_OPTIONS_OR_ALATERNATE_PRODUCTS)
  const [addToBasketMutation] = useMutation(ADD_DELIVERY_PASS_TO_CART)
  const [removeFromCartMutation] = useMutation<{ removeFromCart: Cart }>(REMOVE_FROM_CART)
  const { saveUserJourneyAnalytics } = useSaveAuthAnalytics()
  const { sendPersonalMessageLocationToAnalytics } = useSendPersonalMessageLocationToAnalytics()

  const [addToCartError, setAddToCartError] = useState<string>('')
  const [digitalProductList, setDigitalProductsList] = useState<ProductData[]>()
  const currentKey = useRef('')
  const [hasActiveDeliveryPass, setHasActiveDeliveryPass] = useState(false)
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const [activeDeliveryPassType, setActiveDeliveryPassType] = useState('false')
  const [isCourierUpgrade, setIsCourierUpgrade] = useState<boolean>(false)
  const [goldLineItemId, setGoldLineItemId] = useState('')
  const { product, funeralUspGallery, deliveryOptionsInformation, productVariantInformation, timedContentInformation } =
    props
  const defaultIndexName = useDefaultIndexName()
  const category: Category | undefined = router.isReady
    ? subject.product?.categories?.find((category: Category) => category.key == queryByCategory) ||
      // If no direct match to category key, use the first category with an ancestor that matches
      subject.product?.categories?.find((category: Category) =>
        category.ancestors?.some((ancestor) => ancestor.key == queryByCategory)
      )
    : undefined
  const [newAddressId, setNewAddressId] = useState('')
  const [countriesList, setCountriesList] = useState<CountriesListProps>()
  const { getFromLocalStorage, saveToLocalStorage } = useLocalStorage()
  let productStoreKey = getFromLocalStorage(PRODUCTS_ORDERED_FROM_STORAGE_KEY)
  useEffect(() => {
    if (router?.isReady && product.key !== currentKey.current) {
      const categoryNames: string[] | undefined = category?.name && [
        category.name,
        ...(category.ancestors ?? [])
          .slice()
          .reverse()
          .map((ancestor) => ancestor.name),
      ]

      const indexName = queryByIndexName?.toString() || defaultIndexName
      analytics.viewProduct(product, indexName, categoryNames)
      currentKey.current = product.key
    }
  }, [product, analytics, defaultIndexName, router, category])

  const [addToCart] = useMutation<AddToCartResult>(ADD_TO_CART, {
    onError: (error: ApolloError) => {
      setAddToCartError(_get(error, ['graphQLErrors', 0, 'extensions', 'code']))
    },
  })

  useQuery(GET_CART, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const activeDeliveryPass = data?.cart?.deliveryPassType
      if (!activeDeliveryPass) return
      const deliveryPassLineItems = data.cart.consignments?.find((consignment) => consignment.isDigital)?.items

      const goldDeliveryPassLineItem = deliveryPassLineItems?.find((deliveryPassLineItem) =>
        deliveryPassLineItem?.lineItem?.sku?.includes('GOLD')
      )?.lineItem?.id
      setGoldLineItemId(goldDeliveryPassLineItem)
      setHasActiveDeliveryPass(true)
      setActiveDeliveryPassType(activeDeliveryPass)
    },
    ssr: false,
  })

  useQuery(GET_DIGITAL_PRODUCTS, {
    onCompleted: (response) => {
      setDigitalProductsList(response?.digitalProducts)
    },
    skip: isFlyingFlowers,
  })

  useQuery(GET_ALL_COUNTRIES_LIST, {
    onCompleted: (response) => {
      const countryList = response.getAllCountriesList.countriesList
      setCountriesList(countryList)
    },
    skip: isFlyingFlowers || product.isInternational || product.isFuneral,
  })

  const [saveCustomerAddress] = useMutation(ADD_NEW_ADDRESS)
  const [updateCustmorAddress] = useMutation(EDIT_CONTACT_ADDRESS)
  const formatAddressCallback = async ({ suggestion, format, variant, countryCode, startDate, endDate }) => {
    const today = new Date()
    const { key, isOverseas } = product
    const inputVariables = {
      startDate: startDate || formatDate(today, 'yyyy-MM-dd'),
      endDate: endDate || formatDate(addMonths(today, 2), 'yyyy-MM-dd'),
      suggestion,
      format,
      productKey: key,
      variantKey: variant,
      countryCode,
      isOverseas: !!isOverseas,
    }
    try {
      return await getFormattedAddressAndAvailability({ input: inputVariables })
    } catch (error) {
      return { error }
    }
  }

  const productAvailabilityCallback = async ({ startDate, endDate, variant, occasion, address }) => {
    const { key, isOverseas } = product
    const inputVariables = {
      startDate,
      endDate,
      productKey: key,
      variantKey: variant,
      occasion,
      address,
      isOverseas: !!isOverseas,
    }
    return await getProductAvailability({ input: inputVariables })
  }

  const deliveryOptionsCallback = async ({
    variant,
    deliveryDate,
    ruleIds,
    deliveryPassType,
    countryCode,
    address,
  }) => {
    const { key } = product
    setAddToCartError('')
    const inputVariables = {
      productKey: key,
      variantKey: variant,
      deliveryDate,
      ruleIds,
      deliveryPassType,
      countryCode,
      address,
    }
    try {
      const deliveryOptionResponse = await getDeliveryOptions({ input: inputVariables })
      const path = !!deliveryOptionResponse.data?.deliveryOptions.length ? 'happy_path' : 'dead_end'
      const postalCode = sessionStorage.getItem('postCodeStorage')
      analytics.oldCalendarJourney(deliveryDate, postalCode, path)
      return deliveryOptionResponse
    } catch (error) {
      return { error }
    }
  }

  const deliveryOptionsOrAlternateProductsCallback: DeliveryOptionsOrAlternateProducts = async (...args) => {
    const [productKey, variantKey, deliveryDate, ruleIds, deliveryPassType, countryCode, address, occasion] = args
    const inputVariables = {
      productKey,
      variantKey,
      deliveryDate,
      ruleIds,
      deliveryPassType,
      countryCode,
      address,
      occasion,
    }
    try {
      const response = await getDeliveryOptionsOrLaternateProductsMutations({ input: inputVariables })
      const deliveryOptions = response.data?.getDeliveryOptionsOrAlternateProducts?.deliveryOptions
      const productSuggestions = response.data?.getDeliveryOptionsOrAlternateProducts?.productSuggestions
      let path = 'dead_end'
      if (deliveryOptions?.length) {
        path = 'happy_path'
      } else if (productSuggestions?.length) {
        path = 'alternate_product'
      }
      analytics.pdpPath(deliveryDate, address?.postalCode, path)
      if (deliveryPassType && deliveryPassType !== '') {
        const idpType = deliveryPassType == 'PLATINUM' ? 'Platinum' : 'Gold'
        analytics.idpJourney('PDP', idpType)
      }
      return response.data?.getDeliveryOptionsOrAlternateProducts
    } catch (error) {
      return { error }
    }
  }

  const isProductFulfillableCallback = async ({
    deliveryDate,
    deliveryServiceCode,
    variant,
    occasion,
    address,
    isCourierUpgrade,
    funeralServiceTime,
    funeralServiceDate,
  }) => {
    const inputVariables = {
      deliveryDate,
      deliveryServiceCode,
      productKey: product.key,
      variantKey: variant,
      occasion,
      address,
      isOverseas: !!product.isOverseas,
      isCourierUpgrade: isFlyingFlowers ? isCourierUpgrade : undefined,
      funeralServiceDate: funeralServiceDate ? formatDate(new Date(funeralServiceDate), 'yyyy-MM-dd') : undefined,
      funeralServiceTime: funeralServiceTime ? funeralServiceTime.replace(':', '') : undefined,
    }
    try {
      setIsCourierUpgrade(isCourierUpgrade)
      analytics.pdp_delivery_date_completed(deliveryDate)
      return await isProductFulfillable({ input: inputVariables })
    } catch (error) {
      return { error }
    }
  }

  const sendStepAnalytics = (step: string, values: any, errors?: any) => {
    const deliveryOption = values.deliveryOptions?.[values.selectedDeliveryOptionIndex || 0]
    const delivery_cost =
      Number(deliveryOption?.standardDeliveryCharge || 0) + Number(deliveryOption?.optionalDeliveryCharge || 0)
    const analyticsStepDetails = {
      VARIANTS: {
        step: 'choose colour & size',
        colour: values.variantColor,
        size: values.variantSize,
      },
      ADDRESS: {
        step: 'delivery address',
        postcode: values.postalCode,
        county: values.county,
        city: values.city,
      },
      OPTION: {
        step: 'delivery option and date',
        delivery_date: values.deliveryDate,
        delivery_option: deliveryOption?.serviceCode,
        delivery_selection: deliveryOption?.title,
        delivery_cost,
        delivery_instruction: values.deliveryInstructionOption,
      },
      PERSONALISE: {
        step: 'personalise your gift',
        occasion: values.occasion,
      },
    }
    const detail = analyticsStepDetails[step] ?? null
    detail && analytics.addToOrder(detail)
    if (step === 'VARIANTS') {
      analytics.productVariantChange(values?.sku)
    }
    if (step === 'ADDRESS' && !!errors) {
      analytics.addressErrorEvent(errors)
    }
  }

  const submitForm = async (values: any) => {
    setIsSubmittingForm(true)
    setAddToCartError('')
    const { isOverseas, isInternational, isDropship } = product
    const search = window.location.search
    const params = new URLSearchParams(search)

    try {
      let deliveryInstructionNote = ''
      if (values.deliveryInstructionNote) {
        deliveryInstructionNote = updateDeliveryInstructionNote(
          values.deliveryInstructionNote,
          values.deliveryInstructionOption
        )
      }
      const response = await addToCart({
        variables: {
          input: populateTemplateValues(
            convertFormValues(
              {
                ...values,
                country: values.country || countryCode,
                isInternational,
                isOverseas,
                isCourierUpgrade: isFlyingFlowers ? isCourierUpgrade : undefined,
                categoryKey: category?.key,
                isDropship: !!isDropship,
                deliveryInstructionNote,
              },
              product
            ),
            product.isFuneral ? FuneralAddToCartTemplate : AddToCartTemplate
          ),
        },
      })
      sessionStorage.removeItem('guidedNavigationValues')
      removeSessionItems(['isFromPLP', 'breadcrumb', 'removedProductLineItem', 'funeralLineItemStore'])

      sendStepAnalytics('PERSONALISE', values)

      if (!response.data) {
        setIsSubmittingForm(false)
        throw { error: response.errors ?? 'Empty add to basket response returned' }
      }

      const { addedLineItemId, consignmentLimitReached, hasFinishingTouches, cart } = response.data.addToCart

      // Get the added line item and send analytics event
      const lineItem = cart?.consignments
        ?.find((consignment) => consignment.items.find((item) => item.lineItem.id === addedLineItemId))
        ?.items.find((item) => item.lineItem.id === addedLineItemId)?.lineItem
      lineItem &&
        analytics.addToCart(
          cart.id,
          lineItem,
          1,
          queryByQueryId?.toString(),
          queryByIndexName?.toString() || defaultIndexName
        )
      const productOrdered = params.get('productOrderedFrom')
      if (productOrdered) {
        const productObj = {
          productName: lineItem.product.name,
          productOrdered,
          lineItem: lineItem.id,
        }
        if (productStoreKey === null) {
          // Initialize productStoreKey with an empty array or any default value
          productStoreKey = []
        }
        productStoreKey?.push(productObj)
        saveToLocalStorage(PRODUCTS_ORDERED_FROM_STORAGE_KEY, productStoreKey)
      }
      const nextPage =
        consignmentLimitReached || isInternational || !hasFinishingTouches
          ? '/basket'
          : `/finishing-touches?id=${addedLineItemId}`
      getConsignmentCount?.refetch()
      router.push(nextPage)
    } catch (error) {
      setIsSubmittingForm(false)
      return { error }
    }
  }

  const onSignIn: HandleSubmit<SignInValues> = async (values) => {
    await signIn(values)
    await getCustomer.refetch()
  }
  const addNewAddress = async (values: Address) => {
    try {
      const addAddressInput = populateTemplateValues(values, AddNewAddressTemplate)
      const savedAddressResponse = await saveCustomerAddress({
        variables: { input: { address: { ...addAddressInput }, defaultAddress: false } },
      })
      setNewAddressId(savedAddressResponse.data?.addAddress?.addressId)
      getCustomer?.refetch()
    } catch (error) {
      throw new Error('There was a problem adding your address, please try again.')
    }
  }

  const updateCustomerAddress = async (values: Address) => {
    try {
      const updateAddressInput = populateTemplateValues(values, AddNewAddressTemplate)
      await updateCustmorAddress({
        variables: { input: { address: { ...updateAddressInput }, defaultAddress: false, id: newAddressId } },
      })
      getCustomer?.refetch()
    } catch (error) {
      throw new Error('There was a problem adding your address, please try again.')
    }
  }

  const updateMissingContactAddress = async (values: any) => {
    const addressFields = populateTemplateValues(values, AddNewAddressTemplate)
    try {
      await updateCustmorAddress({
        variables: {
          input: {
            address: { ...addressFields },
            defaultAddress: values.isDefaultAddress,
            id: values.id,
          },
        },
      })
    } catch (error) {
      return { error }
    }
  }

  const sendDateAnalytics = (date: string) => {
    analytics.alternateProductCalendarJourney(date)
  }

  const addDeliveryPassToCart = async (sku: string, removeGold?: boolean) => {
    if (removeGold && goldLineItemId) {
      await removeFromCartMutation({ variables: { input: { lineItemId: goldLineItemId } } })
      getConsignmentCount?.refetch()
    }
    await addToBasketMutation({ variables: { input: { sku } } })
    router.push('/basket')
  }

  /** sending Open Price Related analytics */
  const sendOpenPriceAnalytics = (variant: string, error?: any, type?: string) => {
    if (type === 'MINIMUM') {
      analytics.minOpenPriceErrorEvent(variant, error)
    } else if (type === 'MAXIMUM') {
      analytics.maxOpenPriceErrorEvent(variant, error)
    } else if (type === 'EMPTY') {
      analytics.openPriceErrorEvent(variant, error)
    } else if (type === 'VALID') {
      analytics.openPriceEvent(variant, error)
    }
  }

  const pdpThumbnailImageClick = (position: number) => {
    analytics.pdpThumbnailClick(position)
  }

  const saveKlarnaLearnMoreAnalytics = () => {
    analytics.klarnaLearnMoreClick()
  }

  return (
    <>
      <ProductDetailUI
        key={product.sku}
        product={product}
        productAvailabilityCallback={productAvailabilityCallback}
        formatAddressCallback={formatAddressCallback}
        deliveryOptionsCallback={deliveryOptionsCallback}
        isProductFulfillableCallback={isProductFulfillableCallback}
        submitForm={submitForm}
        addToCartError={addToCartError}
        isSubmittingForm={isSubmittingForm}
        onSignIn={onSignIn}
        digitalProducts={digitalProductList}
        hasActiveDeliveryPass={hasActiveDeliveryPass}
        funeralUspGallery={funeralUspGallery}
        sendStepAnalytics={sendStepAnalytics}
        addNewAddress={addNewAddress}
        updateContactAddress={updateCustomerAddress}
        newAddressId={newAddressId}
        countriesList={countriesList}
        deliveryOptionsInfo={deliveryOptionsInformation}
        deliveryOptionsOrAlternateProductsCallback={deliveryOptionsOrAlternateProductsCallback}
        updateMissingContactAddress={updateMissingContactAddress}
        sendDateAnalytics={sendDateAnalytics}
        productVariantStyleInformation={productVariantInformation}
        addDeliveryPassToCart={addDeliveryPassToCart}
        activeDeliveryPassType={activeDeliveryPassType}
        sendOpenPriceAnalytics={sendOpenPriceAnalytics}
        saveUserJourneyAnalytics={saveUserJourneyAnalytics}
        saveKlarnaLearnMoreAnalytics={saveKlarnaLearnMoreAnalytics}
        timedContentInformation={timedContentInformation}
        pdpThumbnailImageClick={pdpThumbnailImageClick}
        sendPersonalMessageLocationToAnalytics={sendPersonalMessageLocationToAnalytics}
      />
    </>
  )
}
export default ProductDetail
