/* eslint-disable @next/next/no-sync-scripts */
import Script from 'next/script'
import React from 'react'
/*
 *DYNATRACE TAGGING
 */
export function renderDynaTraceTagging() {
  return (
    <Script
      src="https://js-cdn.dynatrace.com/jstag/16ab023090d/bf42750lhk/a53f37c3167f5414_complete.js"
      strategy="afterInteractive"
      crossOrigin="anonymous"
      type="text/javascript"
    />
  )
}
