import React from 'react'
import algoliasearch from 'algoliasearch'
import { InstantSearch } from 'react-instantsearch-dom'

type Props = {
  children: React.ReactNode
  indexName: string
}

const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

const AlgoliaWrapper = ({ children, indexName }: Props) => {
  const indexValue = `${envPrefix}_${indexName}`
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={
        indexValue.includes(`${envPrefix}_${envPrefix}`) ? indexValue.replace(`${envPrefix}_`, '') : indexValue
      }
    >
      {children}
    </InstantSearch>
  )
}

export default AlgoliaWrapper
